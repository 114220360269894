import React, { useState, useEffect} from 'react';
import { Switch ,Route ,Redirect,useHistory, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import LegalTerms from './Component/FooterModals/LegalTerms/LegalTerms';
import CookiesPolicy from './Component/FooterModals/CookiesPolicy/CookiesPolicy';
import MainComponent from './Component/MainComponent';
import chevronDownIcon from '@tuxedo/bootstrap-theme/images/icons/chevron-down.svg';
import heart from 'assets/images/Heart_title_icon_white.svg';
import wellness from 'assets/images/hand_heart.svg';
import spending from 'assets/images/document.svg';
import notenrolled from 'assets/images/empty_heart.svg';
import dental from 'assets/images/folder.svg';
import medical from 'assets/images/care.svg';
import benefit from 'assets/images/med_case.svg';
import home from 'assets/images/heart_fill.svg';
//import useAdfs from './Component/UseAdfs';
import {
  TuxGlobalNavigation,
  TuxGlobalHeader,
  TuxGlobalFooter,
  TuxIcon
} from '@tuxedo/react-component-library';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import UserComponent from './Component/UserComponent';
import SiteCatalystComponent from './Component/SiteCatalystComponent';
import Constant from './Component/Constant';

const links = [
  { name: Constant.JP_MORGAN_CHASE_NAME, url: Constant.JP_MORGAN_CHASE_LNIK },
  { name: Constant.JP_MORGAN_NAME, url: Constant.JP_MORGAN_LINK },
  { name: Constant.CHASE, url: Constant.CHASE_LINK }
];
const changeHandler = () => {};

let menuItems = [
  {
    menuName: Constant.HOME_PAGE,
    route: Constant.HOME_PAGE_URL,
    //route: '/main/home',
    iconURL: home,
    children: [],
    newWindow: false,
    id: 'menu1',
    onChange: changeHandler,
    className: 'navLinks'
  },
  {
    menuName: Constant.BENIFITS_ENROLMENT_PAGE,
    route: Constant.BENIFITS_ENROLMENT_PAGE_URL,
    //route: '/main/benifitEnrolment',
    iconURL: benefit,
    children: [],
    id: 'menu2'
  },
  {
    menuName: Constant.ACCOUNTS_PAGE,
    route: Constant.ACCOUNTS_PAGE_URL,
    iconURL: spending,
    children: [],
    id: 'menu3'
  },
  {
    menuName: Constant.WELLNESS_PAGE,
    route: Constant.WELLNESS_PAGE_URL,
    iconURL: wellness,
    children: [],
    id: 'menu4'
  },
  {
    menuName: Constant.INSURANCE_PAGE,
    route: Constant.INSURANCE_PAGE_URL,
    iconURL: dental,
    children: [],
    id: 'menu5'
  },
  {
    menuName: Constant.MEDICAL_SPECIALITY_PAGE,
    route: Constant.MEDICAL_SPECIALITY_PAGE_URL,
    iconURL: medical,
    children: [],
    id: 'menu6'
  },
  {
    menuName: Constant.NOT_ENROLLED_MEDICAL_PAGE,
    route: Constant.NOT_ENROLLED_MEDICAL_PAGE_URL,
    iconURL: notenrolled,
    children: [],
    id: 'menu7'
  }
];

function App() {
  const userData = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  let homePageUrl = Constant.HOME_PAGE_URL;
  const [navigationIsExpanded, setNavigationIsExpanded] = useState(false);
  const [navMenu, setNavMenu] = useState([]);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [appUser, setAppUser] = useState({});
  const [empType, setEmpType] = React.useState('');
  const [empState, setEmpState] = React.useState('DEFAULT');
  const [sid, setSid] = React.useState(userData?.userReducer?.sid);
  const [empData, setEmpData] = React.useState({});
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  //useAdfs();
  const handleToggleNavigation = () => {
    setNavigationIsExpanded(!navigationIsExpanded);
  };
  const toggleDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  useEffect(() => {
    if (
      sid === 'undefined' ||
      sid === null ||
      empState === '' ||
      empState === 'DEFAULT'
    ) {
      fetchMonetaUser();
    } else {
      setAppUser(empData);
      if (empType !== 'ACTIVE') {
        menuItems = [];
        setNavigationIsExpanded(false);
        redirectToEmploymentStatusPage(empType);
      } else {
        setNavigationIsExpanded(true);
        setNavMenu(menuItems);
      }
    }
  }, []);

  const fetchMonetaUser = () => {
    fetch(Constant.FETCH_MONETA_USER, {
      mode: 'no-cors',
      credentials: 'same-origin'
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setSid(data.sid);
            dispatch({ type: 'SID', value: data.sid });
            getEmpProfile(data.sid);
            getEmployeeDetails(data.sid);
            SiteCatalystComponent.tracking(
              'Page Load',
              Constant.HOME_PAGE_LOAD,
              '',
              data
            );
          });
        } else {
          const message = `Response Error: ${response.status}`;
          console.error(message);
        }
      })
      .catch((error) => {
        const message = `Error: ${JSON.stringify(error)}`;
        console.error(message);
      });
  };
  const getEmpProfile = (sid) => {
    sid = sid.toUpperCase();
    UserComponent.getEmpProfile(Constant.FETCH_EMP_PROFILE)
      .then((data) => {
        console.log("~~~~~~~~~~~~~~~~~ getEmpProfile() -> EmployeeProfile:: " + data);
        const employmentStatus = Constant.EMPL_STATUS_CODE_MAP.get(data?.empType);
        dispatch({ type: 'EMPTYPE', value: employmentStatus });

        if (employmentStatus !== 'ACTIVE') {
          menuItems = [];
          setNavigationIsExpanded(false);
          redirectToEmploymentStatusPage(employmentStatus);
        } else {
          setNavigationIsExpanded(true);
          setNavMenu(menuItems);
        }
        setEmpType(employmentStatus);
        dispatch({ type: 'VENDOR', value: data?.vendor });
      })
      .catch((error) => {
        const message = `Error: ${JSON.stringify(error)}`;
        console.log('Error in getEmpProfile');
        console.error(message);
        history.push('/error_redirect');
      });
  };
  const redirectToEmploymentStatusPage = (employmentStatus) => {
    switch (employmentStatus) {
      case 'LTD':
        homePageUrl = Constant.LTD_HOME_PAGE_URL;
        history.push(homePageUrl);
        break;
      case 'SEVRCR':
        homePageUrl = Constant.SEVRCR_HOME_PAGE_URL;
        history.push(homePageUrl);
        break;
      case 'RETIRE':
        homePageUrl = Constant.RETIRE_HOME_PAGE_URL;
        history.push(homePageUrl);
        break;
      default:
        console.log('Error in redirectToEmploymentStatusPage');
        history.push('/error_redirect');
    }
  };
  const getEmployeeDetails = (sid) => {
    sid = sid.toUpperCase();
    fetch(Constant.GET_EMP_DETAILS, {
      mode: 'no-cors',
      credentials: 'same-origin'
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setAppUser(data);
            setEmpData(data);
            dispatch({ type: 'EMPDATA', value: data });
            dispatch({ type: 'EMPSTATE', value: data.state });
            setEmpState(data.state);
          });
        } else {
          const message = `Response Error: ${response.status}`;
          return { error: message };
        }
      })
      .catch((error) => {
        const message = `Error: ${JSON.stringify(error)}`;
        return { error: message };
      });
  };

  const redirectLogOut = () => {
    window.location.href = '/api/adfs/logout';
  };
  const host = window.location.hostname;
  const url = window.location.href;
  let hideLogout = true;
  if (
    url &&
    (url.indexOf('jpmchase.net') > -1 || host.indexOf('jpmchase.net') > -1)
  ) {
    hideLogout = false;
  }

  const currentYear = moment().format('YYYY');
  const classes = classNames('content', {
    'navigation-expanded': navigationIsExpanded
  });
  return empState !== 'DEFAULT' && empType !== '' ? (
    <div className="app-container bg-light">
      <TuxGlobalNavigation
        // id="navLinks"
        menu_data={navMenu}
        expanded={navigationIsExpanded}
        onChange={handleToggleNavigation}
      />

      <div className={classes}>
        <TuxGlobalHeader>
          <div className="left_side" />
          <a
            className="navbar-brand center internal"
            href={Constant.HOME_PAGE_URL}
          >
            <img className="img-fluid imgDisplay" src={heart} alt="JPMC logo" />
            <span className="text-white" role="heading" aria-level="1">
              My Health
            </span>
          </a>
          <div className="right_side">
            <Dropdown
              className="profile"
              isOpen={dropdownIsOpen}
              toggle={toggleDropdown}
            >
              <DropdownToggle color="link">
                <span>{appUser !== null ? appUser?.name : empData?.name}</span>
                {hideLogout && <TuxIcon svg={chevronDownIcon} />}
              </DropdownToggle>
              {hideLogout && (
                <DropdownMenu>
                  <DropdownItem onClick={() => redirectLogOut()}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              )}
            </Dropdown>
          </div>
        </TuxGlobalHeader>

        <main className="content-body">
          <Switch>
            <Redirect exact from="/pc" to={homePageUrl} />
            <Route path="/*" component={MainComponent} />
            <Redirect exact from="/*" to={homePageUrl} />
          </Switch>
        </main>

        <TuxGlobalFooter links={links}>
          <LegalTerms />
          <br />
          <CookiesPolicy />
          <p>
            Information on this site is for JPMC use only. Do not share
            non-public information outside the firm.
          </p>

          <p>
            &copy;{currentYear} JPMorgan Chase &amp; Co. All rights reserved.
            <br />
            Stock prices are 20-minute delayed and are provided by
            Intercontinental Exchange
            <br />
            they should not be used as a basis for trade without further
            verification.
          </p>
        </TuxGlobalFooter>
      </div>
    </div>
  ) : null;
}

export default App;

//import React, {useEffect} from 'react';
import './ExternalPage.scss';
import {TuxGlobalFooter, TuxGlobalHeader} from "@tuxedo/react-component-library";
import heart from 'assets/images/Heart_title_icon_white.svg';
import LegalTerms from "../../FooterModals/LegalTerms/LegalTerms";
import CookiesPolicy from "../../FooterModals/CookiesPolicy/CookiesPolicy";
import moment from "moment";
import SiteCatalystComponent from "../../SiteCatalystComponent";
import {useEffect} from "react";
const links = [
  { name: 'JPMorgan Chase', url: 'https://www.jpmorganchase.com' },
  { name: 'J.P. Morgan', url: 'https://www.jpmorgan.com' },
  { name: 'Chase', url: 'https://www.chase.com' },

];

function ExternalPage(){
  useEffect(() =>  {
    SiteCatalystComponent.tracking("Page Load","home");
  },[]);

  const handleClick=(userType,link)=>{
    if(userType===undefined){
      userType="Employee";
    }
    localStorage.setItem("USERTYPE", userType);
    localStorage.setItem("openNewWindow","false");
  };

  const currentYear = moment().format('YYYY');
  var loginpath="/pc/MyHealth_2_0_Home1.aspx?t="+ moment().format('hhmmss');
  return (
    <div className="ExternalPage">
      <div className="container-fluid containerFormat">
        <div className="row headerRow">
          <TuxGlobalHeader>
            <div className="left_side">
              <a className="navbar-brand center" href="#">
                <img className="img-fluid imgDisplay" src={heart}
                     alt="JPMC logo"
                />
                <span className="text-white">My Health</span>
              </a>
            </div>
          </TuxGlobalHeader>
        </div>
        <div className="row bg-white middleRow">

          <div>
            <br/> <br/>
            <table>
              <tbody>
              <tr>
                <td className="borderFormat">&nbsp;&nbsp;&nbsp;&nbsp;
                </td>
                <td>

                  <h2>Welcome to <b>My Health</b> for JPMorgan Chase!</h2>
                  <h6>This portal has information that can help you lead a healthier life and understand your health
                    care options. Find the appropriate site for you below.</h6>
                </td>
              </tr>
              </tbody>
            </table>
            <br/> <br/>
          </div>

        </div>
        <div className="row middleRow2">
          &nbsp;
        </div>
        <br/>

        <div className="row justify-content-center middleRow2">
          <div className="col-md-9">
            <h3 className="headerFormat"> Find your site:</h3>
          </div>
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="bg-white">
                  <div className="card card-shadow">
                    <div className="card-body">

                      <div className="row txt-color">

                        <div className="col-md-6 bold">

                          <p className="bold" style={{color:'black'}} >
                            Current/Former Employees:
                          </p>
                          <br/>
                          <a className="btn emphasized_link login-btn-align" href={loginpath}
                          >Login Here</a>
                          <br/>
                        </div>

                        <div className="col-md-6 ">
                          <p className="bold" style={{color:'black'}}>
                            Covered spouses, domestic partners and dependents of employees:
                          </p>

                          <p>
                            {/*<a href="/Act_tab_Spouses_Domestic_Partners_np.aspx"
                               onClick={() => handleClick('Spouse/Partner Other',"/Act_tab_Spouses_Domestic_Partners_np.aspx")}>U.S. Residents (not California) </a><br/>*/}
                            {/*<a href="/Act_tab_Spouses_Domestic_Partners_Category_np.aspx"*/}
                            {/*   onClick={() => handleClick('Spouse/Partner',"/Act_tab_Spouses_Domestic_Partners_Category_np.aspx")}>U.S. Residents (Arizona & Ohio)</a> <br/>*/}
                            <a data-testid="California" href="/Act_tab_Spouses_Domestic_Partners_Category2_np.aspx"
                               onClick={() => handleClick('California',"/Act_tab_Spouses_Domestic_Partners_Category2_np.aspx")}>U.S. Residents (<span className="bold">California</span>)</a> <br/>
                            <a data-testid="Florida, Georgia, Louisiana and Oklahoma" href="/Act_tab_Spouses_Domestic_Partners_category1_np.aspx"
                               onClick={() =>handleClick('Retiree/LTD',"/Act_tab_Spouses_Domestic_Partners_category1_np.aspx")}>U.S. Residents (<span className="bold">Florida, Georgia, Louisiana and Oklahoma </span>)</a> <br/>
                            <a data-testid="All other States" href="/Act_tab_Spouses_Domestic_Partners_np.aspx"
                               onClick={() =>handleClick('Retiree/LTD',"/Act_tab_Spouses_Domestic_Partners.aspx")}>U.S. Residents (<span className="bold">all other States</span>)</a> <br/>
                            <a data-testid="Retirees & Medicare Eligible LTD" href="/Retire_Medicare_EligibleLTD_np.aspx"
                               onClick={() =>handleClick('Retiree/LTD',"/Retire_Medicare_EligibleLTD_np.aspx")}>Retirees & Medicare Eligible LTD (all States)</a> <br/>
                          </p>

                        </div>


                      </div>

                      <br/>
                      <br/>
                      <br/>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row middleRow2">
            <div className="col-md-12">
              <br/><br/><br/><br/><br/><br/><br/>
              <br/><br/><br/><br/>
            </div>
          </div>
        </div>
      </div>
      <TuxGlobalFooter links={links}>
        <LegalTerms/><br />
        <CookiesPolicy/>
        <p>
          Information on this site is for JPMC use only. Do not share
          non-public information outside the firm.
        </p>

        <p>
          &copy;{currentYear} JPMorgan Chase &amp; Co. All rights
          reserved.
          <br />
          Stock prices are 20-minute delayed and are provided by
          Intercontinental Exchange
          <br />
          they should not be used as a basis for trade without further
          verification.
        </p>
      </TuxGlobalFooter>
    </div>
  );

}

export default ExternalPage;
